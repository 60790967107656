/**
 * Given a gross amount and a tax rate, returns the total amount of tax.
 *
 * @param gross Gross amount to calculate tax from.
 * @param taxRate Tax rate to apply, in 2 digit percentage (e.g. 20% = 20).
 */

export const calculateTotalVAT = (gross: number, taxRate: number) => {
  return gross - gross / ((100 + taxRate) / 100);
};

import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceItem } from 'src/app/entities/invoice-item.model';

@Pipe({
  name: 'invoiceTotal',
  pure: false,
  standalone: true,
})
export class InvoiceTotalPipe implements PipeTransform {
  transform(invoiceItems: InvoiceItem[], ...args: any[]): any {
    return invoiceItems
      .map((item) => item.price * item.amount)
      .reduce((sum, value) => sum + value, 0);
  }
}

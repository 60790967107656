// progress-dialog.component.ts
import { Component } from '@angular/core';
import { MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-invoice-generating-dialog',
  template: `
    <h1 mat-dialog-title>
      {{ 'office.invoice.generating_invoice' | translate }}
    </h1>
    <div mat-dialog-content>
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  `,
  styles: [
    `
      mat-spinner {
        display: block;
        margin: 0 auto;
      }
    `,
  ],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatProgressSpinner,
    TranslateModule,
  ],
})
export class InvoiceGeneratingDialogComponent {}

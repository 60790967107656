import { User } from '../../entities/user.model';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

function formatCurrencyForUser(
  value: number,
  currentUser: User,
  currency: string
) {
  return formatCurrency(
    value,
    currentUser.locale,
    getCurrencySymbol(currency.toUpperCase(), 'narrow', currentUser.locale)
  );
}

export { formatCurrencyForUser };

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CryptoService } from 'src/app/components/messaging/service/crypto.service';
import { Note } from 'src/app/entities/note.model';
import { GenericHttpService } from '../../services/Generic HTTP/generic-http.service';
import { AlertService } from '../alert/service/alert.service';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  private showModalSource = new BehaviorSubject<boolean>(false);
  public showModal = this.showModalSource.asObservable();

  private noteSource = new BehaviorSubject<Note>(null);
  public note = this.noteSource.asObservable();

  public key: string;

  constructor(
    private http: GenericHttpService,
    private cryptoService: CryptoService,
    private alertService: AlertService
  ) {}

  getClientNotes(client_uid: string): Observable<Note[]> {
    return this.http.get('/clientcenter/clients/' + client_uid + '/notes').pipe(
      map((response) => {
        return response.data.map((data) => data.attributes);
      })
    );
  }

  createNewNoteForClient(key: string, client_uid: string) {
    this.key = key;
    let n = new Note();
    n.client_uid = client_uid;
    this.noteSource.next(n);
    this.showModalSource.next(true);
  }

  editNote(key: string, note: Note, client_uid: string) {
    this.key = key;
    note.client_uid = client_uid;
    this.noteSource.next(note);
    this.showModalSource.next(true);
  }

  closeModal() {
    this.showModalSource.next(false);
    this.noteSource.next(null);
    this.key = null;
  }

  saveNote(note: Note): Promise<any> {
    var _note = { ...note };
    return this.cryptoService
      .encryptNoteWithKey(_note, this.key)
      .then((encryptedNote) => {
        this.http
          .post(
            '/clientcenter/clients/' + note.client_uid + '/notes/',
            encryptedNote
          )
          .pipe((data) => {
            this.alertService.success('clientcenter.notes.alert.saved');
            this.closeModal();
            this.getClientNotes(note.client_uid);
            return data;
          });
      });
  }

  // sendNote = (note: Note): Observable<Note> => this.http.post("/notes", note);

  // updateNote = (note: Note): Observable<Note> =>
  //   this.http.put(`/notes/${note.id}`, note);

  deleteNote = (note: Note, client_uid: string): Observable<any> =>
    this.http.delete(
      '/clientcenter/clients/' + client_uid + '/notes/' + note.id
    );
}
